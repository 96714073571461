/* You can add global styles to this file, and also import other style files */
/* regular style toast */
/* @import '~ngx-toastr/toastr'; */
.hightlight-anim{
    animation: zoom-in-out 1s ease-out infinite;
  }
  @keyframes zoom-in-out {
    0% {
      transform: scale(1, 1);
      background: yellow;
    }
    50% {
      transform: scale(1.03, 1.03);
      background: darkred;
      color: #fff;
      box-shadow: inset 0px 2px 4px 0px #1b00ff;
    }
    100% {
      transform: scale(1, 1);
      background: blue;
    }
}
.customClass {position: relative;}
iframe{
    border: none;
    width: 100%;
}
.dn { display: none; }
.text-green { color: green; }
.text-red { color: red; }
.bonus_lists .card-header{
    background: var(--theme2-bg);
    border: none;
}
.bonus_lists .card-header button{
    padding: 0;
    color: #fff;
    display: block;
    width: 100%;
    text-align: left;
}
.bonus_lists .card-header [aria-expanded="true"] button:after{
    font-family: Font Awesome\ 5 Free;
    content: "\f0d7";
    color: #fff;
}
.bonus_lists .card-header [aria-expanded="false"] button:after{
    font-family: Font Awesome\ 5 Free;
    content: "\f0d9";
    color: #fff;
}
.bonus_lists .panel-collapse .panel-body {
    background: #fff;
    padding: 8px;
    font-size: 12px;
    text-align: justify;
}
.bonus_lists .progress-bar-striped{
    background: var(--theme1-bg);
}
.bonus_tabs ul li a{
    color: #fff;
    background: var(--theme2-bg);
}
.bonus_tabs ul li a.active,
.bonus_tabs ul li a:hover{
    background: #000;
    color: #fff;
}

iframe.custom-ifrm { width: 100%; border: none; min-height: 89vh;}
.full-width-modal { width: 100%; max-width: 100%; margin: 0 !important; overflow-y: hidden;}
.full-width-modal .modal-content {height: 100vh; background-image: linear-gradient(var(--theme1-bg), var(--theme2-bg));}
.full-width-modal .modal-body {background-image: linear-gradient(var(--theme1-bg), var(--theme2-bg));}
.full-width-modal .modal-header {border: 0;}
#casino-frm iframe { width: 100%; height: calc(100vh - 50px);}

typeahead-container { overflow-y: inherit!important; height: auto!important; padding: inherit!important; }

.btn-custom-loader-d, .btn-custom-loader-m { position: relative; }

.btn-custom-loader-m i { top: 7px; right: 0; left: 0; position: absolute; }
.btn-custom-loader-d i { top: 10px; right: 0; left: 0; position: absolute; }
.LiveStream-video-col .bgimage {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.competition-head {

}
.competition-head h3 {
    width: 100%;
    display: block;
    background: var(--theme2-bg);
    font-size: 15px;
    font-weight: bold;
    margin: 0;
    padding: 2px 14px;
    color: var(--secondary-color);
}
.tooltip-inner{
    width: 330px;
    padding:5px 0;
}
.tooltip-inner p{
    padding: 0;
    margin: 0;
    font-size: 12px;
}
.tooltip-inner p strong{
    font-weight: 800;
}